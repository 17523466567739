@use '../../utils/global';

.header {
  z-index: 2;
  position: sticky;
  top: 0;
  right: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-family: global.$font-family;
  font-size: 30px;
  background-color: global.$background-color;

  .about {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icons {
      margin-right: 20px;
      font-size: 20px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-device-width: 480px), (max-width: 480px) {
  span {
    display: none;
  }
  .header {
    justify-content: end;
  }
}

@media screen and (max-device-width: 769px), (max-width: 769px) {
  .header {
    font-size: 25px;
  }
}
