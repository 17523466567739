@use "../../utils/global";

.ImportExportContainer {
  background-color: global.$card-background-color;
  margin: 20px;
  flex-grow: 1;
  border-radius: 15px;
  text-align: center;
  padding: 0px 10px;
  p {
    font-family: "Pacifico", sans-serif;
    font-size: 20px;
  }
}
