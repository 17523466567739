/* Do not include anything but variables, function declarations 
 * and other SASS features in your global.scss file. 
 * If you include actual CSS, it will duplicate this CSS across 
 * every file you import the global into.
 */

// Variables
$background-color: rgb(224, 236, 241);
$card-background-color: rgb(208, 225, 234);
$tag-color: rgb(163, 197, 212);

// Font
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
$font-family: "Pacifico", sans-serif;
