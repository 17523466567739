@use "../../utils/global";

.dashboardContainer {
  margin: 20px;
  flex-grow: 1;
  border-radius: 15px;
  text-align: center;
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}
