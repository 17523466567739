.RideDetail {
  width: 100px;
  min-height: 50px;
  padding: 3px;
  font-size: 20px;
  text-align: start;
  color: black;

  span {
    color: grey;
    font-size: 17px;
  }
}
