@use "../../utils/global";

.rideContainer {
  background-color: global.$card-background-color;
  margin: 20px;
  flex-grow: 1;
  border-radius: 15px;
  text-align: center;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  .info {
    width: 48%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow: auto;
    padding-right: 10px;
    .title {
      width: 100%;
      font-family: global.$font-family;
      font-size: 25px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      span {
        font-size: 15px;
        font-family: "Courier New", Courier, monospace;
        text-transform: uppercase;
      }
    }
  }

  .rideContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
    justify-content: center;
    grid-gap: 20px;
  }

  .mapContainer {
    border-radius: 15px;
    width: 48%;
    height: 100%;
    overflow: hidden;
    backdrop-filter: filter(100%);
  }
}
