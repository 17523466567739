.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-height: 100vh;
  background-color: rgb(224, 236, 241);

  .container {
    padding: 20px;
    flex-grow: 1;

    .content {
      max-height: 80%;
      height: 80%;
      display: flex;
    }
  }
}
