@use '../../utils/global';

.ridesContainer {
  background-color: global.$card-background-color;
  overflow: auto;
  border-radius: 15px;
  text-align: center;
  padding: 0px 10px;
  p {
    font-family: global.$font-family;
    font-size: 20px;
  }
}
