.sidePanel {
  position: sticky;
  left: 0;
  width: 70px;
  min-width: 70px;
  background-color: black;
  color: white;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  height: 95vh;
  overflow-y: auto;
  align-self: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    /* Hiding scrollbar for Chrome, Safari and Opera */
    display: none;
  }

  .icons {
    font-size: 25px;
    margin: 15px 0px;

    &:first-child {
      margin-bottom: 50px;
    }
  }

  .selectedElement {
    border-left: white solid 4px;
  }
}
