@use "../../../utils/global";

.card {
  width: 200px;
  height: 200px;
  background-color: global.$card-background-color;
  margin: 20px;
  border-radius: 15px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-template-rows: repeat(3, 1fr);

  .icon {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 50px;
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown {
    padding-right: 20px;
    padding-top: 30px;
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .km {
    grid-column: 1/3;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }

  .ratio {
    grid-column: 1/3;
    grid-row: 3;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    font-size: 20px;
    span {
      padding: 5px 10px;
      border-radius: 15px;
      margin-bottom: 5px;
    }
  }
}

.positive {
  background-color: rgb(178, 213, 194);
  color: rgb(93, 142, 97);
}

.negative {
  background-color: rgb(215, 191, 205);
  color: rgb(168, 94, 112);
}
