.loggedInContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    margin: 15px 0px;
    padding-bottom: 5px;
    border-bottom: solid 1px grey;
  }

  .signIn {
    width: 200px;
    margin-top: 20px;
  }
}
