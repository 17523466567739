.importContainer {
  margin-top: 30px;
  padding: 0px 25%;

  .stepContent {
    // height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .uploadButton {
      padding-right: 25%;
    }
  }

  .buttonSteps {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
  }

  .rideSave {
    margin-top: 30px;
  }
}
